<template>
  <BodySimple :loading="firstLoading">
    <template #header>
      <div class="flex items-center">
        <img src="@/assets/images/icons8-vip-96.png" class="h-20 ml-2" />
        <div class="text-xl font-bold">کانال VIP</div>
      </div>
      <h2 class="text-xl font-bold mt-3">
        <Icon name="Group"></Icon>
        {{ channel.name }}
      </h2>
      <div class="mt-3 text-right" dir="ltr">{{ channel.cid }}</div>
    </template>
    <template #default>
      <div
        v-if="!channel.pro_tools"
        class="
          p-3
          bg-red-100
          border-red-200
          text-red-900
          font-bold
          border
          rounded-md
          mb-5
          block
        "
      >
        <div class="flex items-center">
          <img src="@/assets/images/icons8-brawl-stars-96.png" />
          این قابلیت فقط برای اشتراک‌های آبی، برنزی، نقره‌ای و طلایی وجود دارد.
        </div>
      </div>
      <div class="p-3 bg-blueGray-100 border border-blueGray-200 rounded-md mb-5">
        <div class="text-lg font-bold">
          <img src="@/assets/images/icons8-vip-96.png" class="inline-block h-20" />
          فعال سازی حالت VIP
        </div>
        <!-- <div class="text-lg font-bold text-center text-sky-600 mt-3">
          https://t.me/{{ group.bot.username }}?start=group_{{ group.gid }}
        </div> -->
        <p class="mt-5 text-xl text-red-600">
          <b>توجه:</b>
          قبل از فعال سازی حالت VIP مطمئن شوید که می‌دانید چکار می‌کنید و موارد زیر را با
          دقت بخوانید
        </p>
        <ul class="list-inside list-disc mt-5">
          <li>
            حالت VIP برای کانال هایی مناسب است که میخواهند از کاربران حق اشتراک بگیرند.
            مانند کانال های آموزشی، پرسش و پاسخ و سیگنال دهی و...
          </li>
          <li>
            برای فعال سازی حالت VIP لازم است که یکی از اشتراک های آبی،برنزی، نقره‌ای یا
            طلایی را خریداری کرده باشید.
          </li>
          <li>هنگام تسویه حساب درامد کانال از فروش اشتراک 25 درصد آن کسر می‌شود.</li>
          <li>
            با فعال سازی حالت VIP هر کاربری با لینک وارد کانال شود اخراج می‌شود و فقط
            کاربرانی می‌توانند وارد شوند که اشتراک خریداری کرده باشند.
          </li>
          <li>
            کاربرانی که زمان اشتراک آنها تمام شود به طور خودکار از کانال حذف می‌شوند.
          </li>
          <li>
            برای ایجاد یک کانال VIP باید تنظیمات دیگری هم در کانال انجام شود.
            <a href="https://youtu.be/tx_Z3ZVdJAA" target="_blank" class="text-sky-500">
              برای آموزش ساخت یک کانال VIP میتوانید این ویدیو را در یوتیوب مشاهده کنید.
            </a>
          </li>
        </ul>
        <div class="text-center">
          <Button
            href="https://youtu.be/tx_Z3ZVdJAA"
            target="_blank"
            color="red"
            class="mt-5 inline-flex"
          >
            آموزش ایجاد کانال VIP در یوتیوب
          </Button>
        </div>
        <hr class="border-blueGray-200 my-5" />
        <div
          class="p-3 bg-blueGray-50 border border-blueGray-200 rounded-md mb-5"
          dir="ltr"
        >
          <div>
            mid link:
            <span class="text-lg font-bold text-sky-600">
              https://t.me/mnrcbot?start=channel_{{ channel.cid }}
            </span>
          </div>
          <div>
            VIP link:
            <span class="text-lg font-bold text-sky-600">
              {{
                res?.result?.invite_link ||
                L_.find(channel.links, function (o) {
                  return o.name == "vip_link";
                })?.invite_link
              }}
            </span>
          </div>
          <Button
            @click="revokeLink"
            color="amber"
            class="h-10 mt-5 inline-flex"
            dir="rtl"
          >
            تغیر لینک VIP
          </Button>
        </div>
        <MnrCheckSlider v-model:checked="vipMod">حالت VIP</MnrCheckSlider>

        <div class="grid xl:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-3 mt-3">
          <MnrNumber
            v-model="channel.free_days"
            title="روز های رایگان"
            hint="برای غیرف عال کردن 0 وارد کنید"
            :min="0"
          />
        </div>
        <p class="mt-3">
          روزهای رایگان یعنی افرادی که برای اولین بار است وارد کانال شما می‌شوند به تعداد
          روز مشخص شده اشتراک رایگان دریافت می‌کنند. و بعد از آن باید خرید کنند در غیر این
          صورت حذف می‌شوند. فعال کردن این گزینه اختلالی در عمل کرد ربات ایجاد نمی‌کند.
          کاربر اگر برای اولین بار درخواست عضویت در کانال کند ربات درخواست آن را قبول
          میکند به تعداد روز های مشخص برای آن اشتراک رایگان در نظر می‌گیرد
        </p>
        <hr class="my-3 border-blueGray-300" />
        <MnrCheckSlider v-model:checked="channel.free_user">
          حالت ممبر رایگان
        </MnrCheckSlider>
        <div class="grid xl:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-3 mt-3">
          <MnrNumber
            v-model="channel.free_user_limit"
            title="حداکثر تعداد ممبر"
            :min="0"
          />
          <MnrNumber v-model="channel.free_user_days" title="تعداد روز" :min="0" />
        </div>
        <p class="mt-3">
          در حال ممبر رایگان می‌توانید مشخص کنید که اگر تعداد ممبر های کانال شما کمتر از
          مقدار مشخص بود کاربران بتوانند بدون خرید اشتراک وارد کانال شوند.
        </p>
        <div class="text-center">
          <Button class="mt-5 inline-flex" @click="submit">ثبت تغییرات</Button>
        </div>
      </div>

      <!-- accounts-->

      <div
        class="p-3 bg-blueGray-100 border border-blueGray-200 rounded-md mt-5"
        id="accounts"
      >
        <div class="text-lg font-bold">
          <img
            src="@/assets/images/icons8-membership-card-96.png"
            class="inline-block h-20"
          />
          اشتراک ها
        </div>
        <ul class="my-5 list-inside list-disc">
          <li>
            با ایجاد اشتراک کاربران میتوانند آنها را خریداری کرده و به طور خودکار کاربر
            VIP شوند
          </li>
          <li>شما می‌توانید اشتراک های مختلفی با مدت زمان و قیمت متفاوت ایجاد کنید</li>
        </ul>
        <div class="grid xl:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-3 mt-3">
          <div
            v-for="account in channel.accounts"
            :key="account.id"
            class="bg-blueGray-50 border border-blueGray-200 p-3 rounded-md"
          >
            <div class="border-b border-blueGray-200 pb-3">
              <img
                src="@/assets/images/icons8-membership-card-96.png"
                class="inline-block h-10"
              />
              {{ account.name }}
            </div>
            <div class="flex p-2">
              <div class="w-1/2">قیمت</div>
              <div class="w-1/2 text-left">{{ $number.format(account.price) }}</div>
            </div>
            <div class="flex p-2">
              <div class="w-1/2">مدت</div>
              <div class="w-1/2 text-left">{{ account.days }}</div>
            </div>
            <div class="p-2 h-20 overflow-hidden">
              {{ account.info }}
            </div>
            <div class="flex p-2 gap-2">
              <Button
                :to="{
                  name: 'ChannelOwnerSettingAccountEdit',
                  params: {
                    id: $store.state.user.id,
                    channelId: channel.id,
                    accountId: account.id,
                  },
                }"
                class="mt-3 text-sm h-11 lg:text-lg"
                color="teal"
              >
                ویرایش
              </Button>
              <Button
                @click="deleteAccount(account.id)"
                class="mt-3 text-sm h-11 lg:text-lg"
                color="red"
              >
                حذف
              </Button>
            </div>
          </div>
        </div>
        <Button
          :to="{
            name: 'ChannelOwnerSettingAccountNew',
            params: { id: $store.state.user.id, channelId: channel.id },
          }"
          class="mt-3 inline-flex text-sm h-11 lg:text-lg"
          color="teal"
        >
          اضافه کردن
        </Button>
      </div>
    </template>
  </BodySimple>
</template>

<script>
import BodySimple from "@/layouts/BodySimple.vue";
import Icon from "@/components/icon/Icon.vue";
import Button from "@/components/Button.vue";
import MnrCheckSlider from "@/components/mnr/MnrCheckSlider.vue";
import _ from "lodash";
import MnrNumber from "@/components/mnr/MnrNumber.vue";

export default {
  components: {
    BodySimple,
    Icon,
    Button,
    MnrCheckSlider,
    MnrNumber,
  },
  data() {
    return {
      firstLoading: true,
      okClass: ["border-green-400"],
      failClass: ["border-red-400"],
      channel: {},
      res: false,
      vipMod: false,
      errors: [],
      L_: _,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      let $this = this;
      $this.firstLoading = true;
      $this.$axios
        .get(`/api/owner-channels/${$this.$route.params.channelId}/vip`, {
          params: {
            id: $this.$store.state.user.id,
            // date: $this.date,
          },
        })
        .then(function (response) {
          // console.log(response);
          $this.channel = response.data.channel;
          $this.vipMod = response.data.channel.vip;
          // $this.users = response.data.users;
          // $this.date = response.data.date;
          if (
            _.find($this.channel.links, function (o) {
              return o.name == "vip_link";
            }) == undefined
          ) {
            $this.revokeLink();
          }
          // if (!$this.group.vipLink) {
          //   $this.revokeLink();
          // }
        })
        .catch(function (error) {
          // console.log(error);
        })
        .then(function () {
          $this.firstLoading = false;
          $this.sending = false;
        });
    },
    revokeLink() {
      let $this = this;
      // $this.firstLoading = true;
      $this.$axios
        .get(`/api/owner-channels/${$this.$route.params.channelId}/vip-link`, {
          params: {
            id: $this.$store.state.user.id,
            // date: $this.date,
          },
        })
        .then(function (response) {
          // console.log(response);
          $this.res = response.data.res;
          // $this.users = response.data.users;
          // $this.date = response.data.date;
        })
        .catch(function (error) {
          // console.log(error);
        })
        .then(function () {
          $this.firstLoading = false;
          $this.sending = false;
        });
    },
    submit() {
      // console.log(update, id);
      // return;
      let $this = this;
      // $this.sending = update;
      // console.log(id, update);
      $this.errors = [];
      $this.$axios
        .put(`/api/owner-channels/${$this.$route.params.channelId}/vip`, {
          id: $this.$store.state.user.id,
          channel: $this.channel,
          vip: $this.vipMod,
        })
        .then(function (response) {
          // console.log(response);
          // $this.group = response.data.group;
          // $this.setting = response.data.setting;
          // $this.otherSetting = response.data.otherSetting;
          // $this.otherGroup = response.data.otherGroup;
          $this.$notify({ text: "تغییرات ذخیره شد", type: "success" });
        })
        .catch(function (error) {
          $this.errors = error.data;
          $this.$notify({ text: "خطا: تغییرات ذخیره نشد", type: "error" });
        })
        .then(function () {
          $this.sending = false;
        });
    },
    deleteAccount(id) {
      // console.log(id);
      // _.remove(this.accounts, ["id", id]);
      let $this = this;
      $this.errors = [];
      // $this.firstLoading = true;
      $this.$axios
        .delete(`/api/owner-channels/${$this.$route.params.channelId}/account/${id}`, {
          params: {
            id: $this.$store.state.user.id,
          },
        })
        .then(function (response) {
          // console.log(response);
          _.remove($this.channel.accounts, ["id", id]);
        })
        .catch(function (error) {
          // console.log(error);
          $this.errors = error.data;
        })
        .then(function () {
          $this.firstLoading = false;
        });
    },
  },
};
</script>

<style></style>
